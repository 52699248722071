import React, { useEffect, useState } from "react";
// import PopPageTen from "./PopPageTen";
import { PopGreetngProp } from "./utils";
import PopOrderStatus from "./PopOrderStatus";
import { useTranslation } from "react-i18next";
import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import {
  functionChangeLanguge,
  getShopDataFromStrg,
  setThemeColorTrackOrder,
} from "../../../utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setCloseAllModal } from "../../../../../../setup/redux/action-creators/close-all-modal";
import { t } from "i18next";
import SideLogoName from "../../../../../shared/components/SideLogoName";
import useBussinessPage from "../../../../../hooks/useBussiness";
const PopGreeting: React.FC = () => {
  // const { onPressClose } = props;
  const navigate = useNavigate();
  const { bussinessName } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const popPageOrderShow = () => {
    // setShowModal(true);
    navigate(`/${bussinessName}/track/order?order=${orderId}&ref=${refId}`);
  };
  const orderId = searchParams.get("order");
  const payment = searchParams.get("payment");
  //orderType
  const orderType = searchParams.get("orderType");
  const reason = searchParams.get("reason");
  // const localData = localStorage.getItem("shop/" + bussinessName);
  const localData = getShopDataFromStrg("/" + bussinessName);
  const [selectedLanguageCode, setSelectedLanguage] = useState<string>(
    localData ? localData?.savedLanguage : "en"
  );
  const localCustomer = localStorage.getItem("customerInfo");
  const shopInfo = localData !== null && localData?.shopInfo;
  const refId = searchParams.get("ref");
  const { updateOrderStatus, trackingOrder, trackOrderVal } = useCustomerInfo();
  const { getUserToken } = useBussinessPage();
  const [userName, setUserName] = useState<string>("");
  const { t } = useTranslation();
  const updateOrderHelper = async (token: string) => {
    await updateOrderStatus(orderId!, refId!, reason!, token);
    if (localData !== null) {
      delete localData.cart;
      localStorage.setItem("shop/" + bussinessName, JSON.stringify(localData));
    }
  };
  // const popPage10Hide = () => {
  //   // setShowModal(false);
  // };
  const onPressClose = () => {
    if (localData !== null && JSON.parse(localData).outletUrl) {
      navigate(JSON.parse(localData).outletUrl);
    } else {
      navigate(`/d/${bussinessName}`);
    }
  };
  const getValues = () => {
    trackingOrder(orderId!, refId!, selectedLanguageCode);
  };

  useEffect(() => {
    if (trackOrderVal !== undefined) {
      setUserName(trackOrderVal.order?.customer_name);
    }
    if (trackOrderVal && shopInfo === false) {
      document.documentElement.style.setProperty(
        "--background-color-store",
        trackOrderVal?.resto?.default_color
      );
      document
        .getElementsByTagName("html")[0]
        .setAttribute(
          "dir",
          trackOrderVal?.resto?.lang === "ar" ? "rtl" : "ltr"
        );
      document
        .getElementsByTagName("body")[0]
        .setAttribute(
          "dir",
          trackOrderVal?.resto?.lang === "ar" ? "rtl" : "ltr"
        );
      setThemeColorTrackOrder(trackOrderVal);
      setSelectedLanguage(trackOrderVal?.resto?.lang);
      functionChangeLanguge(trackOrderVal?.resto?.lang, bussinessName!);
      const favicon = document.getElementById("favicon");

      //@ts-ignore
      favicon.href = trackOrderVal?.resto?.site_logo;
    }
  }, [trackOrderVal]);
  useEffect(() => {
    getValues();
    window.onpopstate = (e) => {
      e.preventDefault();
      onPressClose();
      //your code...
    };
  }, []);
  useEffect(() => {
    // console.log(localData);
    if (payment === "card" && reason === "Success") {
      var token = localData?.accessToken;
      // updateOrderStatus(orderId!, refId!, reason, token);
      if (null !== orderType && orderType === "paymentlink") {
        getUserToken("/" + bussinessName!, true).then(async (tokenNew) => {
          if (tokenNew) {
            token = tokenNew;
          }
          await updateOrderHelper(token);
          // console.log(token);
          // http://localhost:3000/test-resto/thankyou?order=26490&ref=1265&payment=card&reason=Success
        });
        return;
      }
      updateOrderHelper(token);
    }
  }, [payment, reason]);

  return (
    <>
      <div
        id="pop_cart_page9"
        className="thankyouPage pop_up_maps_main pop_main_menu_bg"
        lang={selectedLanguageCode}
      >
        <div className="popup_cart_full popup_from_bottom">
          <div className="content" id="">
            <div className="cart_head_container">
              <div className="container">
                <div className="header_cart_page">
                  <div className="cart_heading">{t("order_placed")}</div>
                  <div
                    // onClick={() => navigate(-1)}
                    className="target_slide glyphicon "
                  ></div>
                </div>
              </div>
            </div>
            {/* <!-- content start here --> */}
            <div className="cart_content">
              <div className="">
                <div className="glyphicon glyphicon-ok-sign cart_tick"></div>
                <div>
                  <h3 className="cart_thanks_dis">
                    {t("thanks")}, {userName}
                  </h3>
                </div>
                <div>
                  <div className="cart_confirmation_alert">
                    {t("order_placed_txt")}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content end here --> */}

            <div className="cart_foot">
              {/* <a className="target_slide" href="#pop_cart_page10"> */}
              <div className="share-button-thankyou">
                <button className="cart_foot_button_rev">
                  <div className="">{t("share")}</div>
                </button>
                <div className="light_grey_text res_font_size1 text_center">
                  {t("share_with_friends")}
                </div>
              </div>
              <button className="cart_foot_button" onClick={popPageOrderShow}>
                <div className="">{t("track_your_order")}</div>
              </button>
              {/* </a> */}
            </div>
          </div>

          {showModal && <PopOrderStatus />}
        </div>
      </div>
    </>
  );
};

export default PopGreeting;

import React from "react";
import CrossSvg from "../../assets/img/svgs/CrossSvg";
interface TermsAndConditionsProps {
  onPressClose: () => void;
  url: string;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = (
  props: TermsAndConditionsProps
) => {
  const { onPressClose, url } = props;
  return (
    <div className=" pop_up_maps_main">
      <div
        className="col-12 popup_cart_full popup_from_bottom  popup_slide"
        // style={{ paddingLeft: 15, paddingRight: 15 }}
      >
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
          }}
          onClick={onPressClose}
        >
          <CrossSvg height={30} width={30} wrapperBgColor={"black"} />
        </div>
        {/* <div onClick={onPressClose}>Close</div> */}
        <br />
        <br />
        {/* <div className="div-heading text-center mt-5 mt-md-0 mb-5">
          <h1>NB FLOWERS </h1>
          <h5>TERMS AND CONDITIONS</h5>
        </div> */}
        <iframe
          style={{ borderWidth: "inherit" }}
          height={"100%"}
          width={"100%"}
          src={url}
          loading="eager"
          title="Custom title"
        ></iframe>
        {/* <div className="row">
          <div className="col-12 col-md-12 col-lg-12 mb-5">
            <p>
              By making a purchase or accessing the Website of NB Flower APP,
              you agree to the following Terms and Conditions. It is your own
              responsibility to ensure that you are fully aware of all of these
              Privacy Policies when making a purchase on
            </p>
            <div>
              <h3>1 Information about us</h3>
              <div>
                NB Flowers business is a private business aiming at providing
                exceptional services and products to match the market demand and
                customer desires. Quality comes as a priority both in products
                provision as well as services offering. Our ultimate goal is to
                obtain customer satisfaction and achieve greater success by
                offering value added services in line with the globalized
                world’s trends of offerings, modern technological
                infrastructure, customer oriented services and excellence in
                internal value chain design and organizational structure. NB
                Flowers business is a unique floral business in the East Coast
                community of the United Arab Emirates and established in the
                city of Fujairah. Its second branch has recently been
                established in Khorfakkan city of Sharjah Emirate, UAE. The
                business’s main focus is Quality and Excellence in customer
                service as well as the products and services offering. Having
                these two elements as core values, we at NB flowers are willing
                to offer the following services to our customers which will make
                us different from the rest of competitors both well-established
                ones and small ones in the market.
                <br />
                <br />
                <div>
                  <ol>
                    <li>
                      Modern showroom/shop with attractive design and elements
                    </li>
                    <li>
                      Offering variety of flowers both cut and potted ones
                    </li>
                    <li>Offering best quality Grade A artificial flowers</li>
                    <li>
                      Offering best quality of preserved flowers (Forever Roses)
                    </li>
                    <li>Offering perfumes</li>
                    <li>Offering home fragrances</li>
                    <li>Offering floral arrangements for events</li>
                    <li>Offering bridal bouquets</li>
                    <li>Delivery service</li>
                    <li>Online website</li>
                    <li>E-commerce and e-purchase facility</li>
                    <li>Credit/debate cards purchase facilities</li>
                    <li>Special promotions on special occasions</li>
                    <li>
                      Entrepreneurship advising for UAE nationals only willing
                      to open their small businesses.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div>
              <h3>2 How the contract is formed between you and us</h3>
              <br />
              <p className="p-3">
                2.1 After placing an order, you will receive online notification
                from us acknowledging that we have received your order. Please
                note that this does not mean that your order has been accepted.
                Your order constitutes an offer to us to buy a Product or
                Service. All orders are subject to acceptance by us. The
                contract between us (Contract) will only be formed when you
                receive the Products and/or Services (as applicable). <br />
                <br />
                2.2 In the case of Products, the Contract will relate only to
                those Products which you receive. A contract for any other
                Products which may have been part of your order will be formed
                when you receive those other Products. <br />
                <br />
                2.3 In the case of Services, these will be governed by the
                relevant terms and conditions applicable to the particular
                service, which will be available on the site to which your
                service relates – please check these terms and conditions before
                proceeding with your order. <br />
                <br />
                2.3.1 Knowing that the service is of your need and acknowledge
                it by placing your order and payment <br />
                <br />
                2.3.2 Making sure in advance that your selected services are
                your final choice and no changes of whatsoever to be requested
                once the order is placed. <br />
                <br />
                2.3.3 Services such as hotel room arrangement or home
                arrangements for birthdays, anniversaries, graduations, baby
                shower and etc. can only be cancelled if ordering person
                requests so, immediately after placing an order. If late
                confirmation or order is received, NB has the right to refuse or
                deduct specific service amount not less than 40% or the actual
                amount placed online <br />
                <br />
                2.3.4 We are not liable if order is requested to be cancelled
                when florists and drivers are already out to deliver or execute
                such service or are on the way to the venue.
              </p>
            </div>
            <div>
              <h3>3 Your status</h3>
              <p className="p-3">
                3.1 By placing an order through our site, you warrant that:{" "}
                <br />
                3.1.1 You are legally capable of entering into binding
                contracts; <br />
                3.1.2 You are at least 16 years old; and <br />
                3.1.3 That you are not resident in a county where making a
                payment to our site, in line with these terms and conditions
                would breach any laws in that county. <br />
              </p>
            </div>
            <div>
              <h3>4. Legal rights:</h3>
              <p>
                NB Flowers has the right to make the following changes if
                necessary:
              </p>
              <div className="p-3">
                4.1 Terminate user account in case of any fraudulent activity is
                observed
                <br /> 4.2 Delete account or delete any ordering entry without
                notifying in case Abusive language or text is generated by any
                party using the online services offered online
                <br /> 4.5 Cancel any order if any fishing activity is observed
                <br /> 4.6 Cancel any order or request if it is by any means not
                parallel to the existing laws of the UAE
                <br /> 4.7 Cancel any order if any abusive name or title is
                observed either in ordering person title or receiver’s
                <br /> 4.8 Ignore or not reply to any email that carries an
                abusive email name, or wordings
                <br /> 4.9 Ignore any text message written to the receiver if it
                contains abusive language or wordings that are against the UAE
                laws, Islamic values, cultural values or insulting any party
                what so ever NB Flowers has the right to report any suspicious
                activity observed or any fraudulent emails or transaction
                received to the legal authorities. It is also the right of NB
                Flowers to report any abusive language or text used during
                communications with our employees. No one has the right to
                verbally abuse any NB Flowers employees no matter what. All
                communications in case of unsatisfactory product or services
                should be in accordance with the cultural and Islamic values of
                the country and no emotional or unlawful language and wordings
                will at no circumstances be accepted.
              </div>
            </div>
            <div>
              <h3>5 Delivery of Products</h3>
              <p>
                In the case of Products, your order will be fulfilled/made ready
                for receipt (as applicable) within a reasonable time of the date
                indicated at the time of ordering, unless there are exceptional
                circumstances.
              </p>
              <div className="p-3">
                5.1 Selected product or services is temporarily out of stock or
                discontinued
                <br />
                5.2 Your payment approval is by any case suspended or rejected
                by any legal financial mediator/gateway/bank
                <br />
                5.3 Your delivery details are incomplete
                <br />
                5.4 Your delivery comments and text to the receiver is abusive
                or against the policies of NB Flowers and local laws
                <br />
                5.5 Your delivery address is written wrong and contradicting
                delivery address or bypassing the delivery charges set out in
                online ordering platform
                <br />
                5.5.1 NB will not deliver any order if delivery price is
                bypassed by customer unless customer agree to pay delivery
                charges. Any delay caused under this article will be the
                responsibility of customer only
                <br />
                5.6 Your delivery notification is received in rush hour during
                festivity seasons such as Eid, Valentine, mother day, women’s
                day and others where all employees and business will be
                extremely busy with walk in customers.
              </div>
            </div>
            <div>
              <h3>6 Warranty</h3>
              <p>
                We warrant to you that any Product purchased from us through our
                site will, on delivery, conform to its description, be of
                satisfactory quality, and be reasonably fit for all the purposes
                for which products of that kind are commonly supplied. All other
                warranties, conditions or terms relating to fitness for purpose,
                merchantability, satisfactory quality or condition whether
                implied by stature or common law are excluded in so far as
                permitted by law.
              </p>
            </div>
            <div>
              <h3>7 Cancellation rights and return policy</h3>
              <p>
                7.1 In the case of Products and services, if you are contracting
                as a customer, you have a statutory right to cancel your Product
                order for any reason and receive a full refund, except in the
                case of certain products listed in paragraph
                <br /> 7.2. You will receive a full refund of the price paid for
                the Products (excluding delivery costs). Your statutory right to
                cancel a Contract relating to the purchase of a Product starts
                from the date when you receive the Product (when the Contract
                between us is formed). If the Products have been delivered to
                you, you may cancel at any time 5 hours, starting from the time
                after you receive the Products subject to the clause stated in
                article 7.3. In the event that you received a Product that is
                damaged on delivery then please inform us of this in writing as
                soon as possible. If a Product is returned to us damaged and you
                have not informed us that the Product was damaged when you
                received it then we may refuse your right to cancel or receive a
                refund.
                <br />
                7.3 You will not have any right to cancel a Contract for the
                supply of any made-to-measure or personalized products such as:
              </p>
              <div className="p-4">
                1. Perishable products such as fresh flowers <br />
                2. Flower arrangements that are confirmed by you by any means of
                communication such as online orders, Whatsapp, direct call or
                walk in to the shop. <br />
                3. Flower bouquets that are returned after disposing them under
                the heat or other unfavorable conditions to perishable products{" "}
                <br />
                4. Flower bouquet and flowers that are by any means damaged or
                returned more than 5 hours without prior notification <br />
                5. Forever roses that are damaged or exposed under heat or
                humidity <br />
                6. Hilum filled balloons that are returned after 4 hours of
                receiving them <br />
                7. Perfumes that are received in good condition and noticed
                broken after the delivery is done and receipt of items in good
                condition is obtained. <br />
                8. Not informing the delivery agent or driver about any defect
                or missing upon delivery <br />
                9. Bouquets that you or your family may consider over price
                comparing to other sellers, this comparison must be done before
                placing an order or purchase transaction is made <br />
                10. Products or services that are purchased by someone else
                cannot be returned by the receiver or any family member <br />
                11. Flowers that are causing unexpected allergies to customers
                if customer not informed us of any such issue when placing the
                order. It is the responsibility of the customer to recognize
                flower types and avoid ordering them and notify us by removing
                them from a bouquet in clear wordings during ordering process.{" "}
                <br />
              </div>
              <br />
              7.4 In the case of Services, your cancellation rights vary
              depending on the service that we are providing you with. A
              cancellation fee may be applicable. Please refer to the particular
              terms and conditions that relate to the Service that you are
              receiving in accordance with paragraph 2.3.
              <br />
              7.5 In the case of Products, to cancel a Contract, you must inform
              us in writing. If the Products have been delivered to you, you
              must also return the Products to us as soon as reasonably
              practicable, and at your own cost. You have a legal obligation to
              take reasonable care of the Products while they are in your
              possession. This can only apply if the product is a hard object
              such as perfumes, vases, artificial flowers and baskets.
              Perishable products are not covered by any means of warranty or
              guaranty.<p></p>
            </div>
            <div>
              <h3>8 Transfer of rights and obligations</h3>
              <p>
                We may transfer our rights and obligations under these terms and
                conditions to another organization, but that will not affect
                your rights or our obligations under your Contract with us. You
                may only transfer your rights and obligations under your
                Contract with us if we agree to this in writing.
              </p>
            </div>
            <div>
              <h3>9 Price</h3>
              <p className="p-3">
                9.1 The price of the Products, Services and our delivery charges
                will be as quoted on our site from time to time, except in cases
                of obvious error. <br />
                9.2 Product and Service prices include VAT, where applicable.
                However, if the rate of VAT changes between the date of your
                order and the date of delivery, we will adjust the VAT you pay,
                unless you have already paid for the Products or Services in
                full before the change in VAT takes effect. <br />
                9.3 Product and Service prices and delivery charges are liable
                to change at any time, but changes will not affect orders for
                Products and/or Services which you then take steps to
                fulfil/receive within a reasonable time of such order.
              </p>
            </div>
            <div>
              <h3>10 Refunds</h3>
              <p className="p-4">
                10.1 If an order is cancelled in accordance with article 7
                above, then we will refund the amounts owed as soon as possible
                following cancellation and in any case within 2-5 days following
                cancellation or shortest possible time depending on the response
                from other technical and financial partners involved. <br />
                <br />
                10.2 If we are due to provide you with a refund then this will
                be made to you directly by visiting the shop and presenting
                legal documents such as valid ID card or passport. The details
                of ordering person sho uld match the walk in person credentials.
                We may also refund you by transferring the refund amount to your
                bank account where complete banking information will be
                necessary to complete the refund transaction. Bank credentials
                must match the ordering person credentials during ordering.{" "}
                <br />
                <br />
                10.3 in the event where the order is placed double due to
                technical issues or customer mistake, we request you to inform
                us immediately by email or call us directly and provide us with
                the reference number of your transaction and full contact
                details which should match the ordering person credentials.{" "}
                <br />
                <br />
                10.4 in the event where you place an order and immediately want
                to cancel and ask for the refund, please call us and provide us
                with your contact information and order reference number. Our
                team will complete the required observations and notify you in
                shortest possible time and inform you of the procedural refund
                policy and information or processing details needed to complete
                the refund.
              </p>
            </div>
            <div>
              <h3>11 How we use your information</h3>
              <p>
                Please read the Privacy Policy
                http://www.nb-flowers/privacypolicy/ for details on how we will
                use your information. By agreeing and accepting these terms and
                conditions you hereby agree and accept the terms of our Privacy
                Policy.
              </p>
            </div>
            <div>
              <h3>12 Our liability to a business</h3>
              <p className="p-3">
                12.1 This paragraph 12 applies when you are making payments in
                the course of a business. <br />
                <br />
                12.2 Subject to paragraph 12.3, if we fail to comply with these
                terms and conditions, we shall only be liable to you for the
                purchase price of the Products and/or Services and, subject to
                paragraph 12.3, any losses that you suffer as a result of our
                failure to comply (whether arising in contract, (including
                negligence), breach of statutory duty or otherwise). <br />
                <br />
                12.3 Subject to paragraph 12.3, we will not be liable for losses
                that result from our failure to comply with these terms and
                conditions that fall into the following categories even if such
                losses were in our contemplation as at the date that the
                contract constituted by these terms and conditions was formed
                between us of being a foreseeable consequence of our breach:{" "}
                <br />
                <br />
                (a) Loss of income or revenue;
                <br />
                (b) Loss of business;
                <br />
                (c) Loss of profits;
                <br />
                (d) Loss of anticipated savings;
                <br />
                (e) Loss of data; or
                <br />
                (f) Waste of management or office time.
                <br />
                11.4 We do not in any way exclude or limit our liability for:
                <br />
                (a) Death or personal injury caused by our negligence;
                <br />
                (b) Fraud or fraudulent misrepresentation;
                <br />
                (c) Defective products under the Consumer Protection Act or
                <br />
                (d) Any other matter for which liability cannot be excluded or
                limited by law. <br />
                <br />
                This paragraph 12 does not apply if you are contracting as a
                consumer. If you are contracting as a consumer please see
                paragraph 13
              </p>
            </div>
            <div>
              <h3>13 Our liability to a Customer</h3>
              <p className="p-3">
                13.1 All products we offer are not consumable in a sense to
                affect the health of customers, if we fail to comply with these
                terms and conditions, we are responsible for loss or damage you
                suffer that is a foreseeable result of our breach of the terms
                and conditions or our negligence. Loss or damage is foreseeable
                if it was an obvious consequence of our breach or it was
                otherwise contemplated by you and us at the time we entered into
                the relevant Contract subject to application of paragraph or
                article 7.3. <br />
                <br />
                13.2 We only supply the Product and Services for domestic and
                private use. You agree not to use the product for any
                commercial, business or re-sale purposes, and we have no
                liability to you for any loss of profit, loss of business,
                business interruption, or loss of business opportunity. <br />
                <br />
                13.3 Some people might have certain allergies with certain
                categories of flowers, we are in no ways responsible for any
                allergic reaction cause doe to customers’ sensitivity to certain
                type of flower, nor liable for any financial damage or direct
                damage caused to the health of a customer. <br />
                <br />
                13.5 Some customers may send flower bouquets to another person
                without disclosing sender’s information. NB flowers will act
                upon such requests as requested by the sender with full
                confidentiality, but if the receiver refuses to receive the
                product without knowing the sender information we will not be
                liable for any refund <br />
                <br />
                13.5.1 in case, a receiver take legal action to know the contact
                information of the sender, NB will not be liable for any
                information provided to legal authorities (only if court case is
                filed and official letter is sent by court) in such case
                customer will be informed and we will act in the interest of the
                court order. <br />
                <br />
                13.5.2 we will not be responsible or liable to any dispute arise
                from article 13.5 between the sender and receiver parties of any
                extent.
              </p>
            </div>
            <div>
              <h3>14 Our right to vary these terms and conditions</h3>
              <p className="p-3">
                14.1 We have the right to revise and amend these terms and
                conditions from time to time.
                <br />
                <br />
                14.2 You will be subject to the policies and terms and
                conditions in force at the time that you order Products and/or
                Services from us, unless any change to those policies or these
                terms and conditions is required to be made by law or
                governmental authority (in which case it will apply to orders
                previously placed by you).
              </p>
            </div>
            <div>
              <h3>15 Our contract with you if you are a business</h3>
              <p className="p-3">
                15.1 These terms and conditions and any document expressly
                referred to in them constitute the whole agreement between us
                and supersede all previous discussions, correspondence,
                negotiations, previous arrangement, understanding or agreement
                between us relating to the subject matter hereof. <br />
                <br />
                15.2 We each acknowledge that, in entering into a Contract,
                neither of us relies on, or will have any remedies in respect
                of, any representation or warranty (whether made innocently or
                negligently) that is not set out in these terms and conditions
                or the documents referred to in them. <br />
                <br />
                15.3 Each of us agrees that our only liability in respect of
                those representations and warranties that are set out in this
                agreement (whether made innocently or negligently) will be for
                breach of contract. <br />
                <br />
                15.4 Nothing in this paragraph limits or excludes any liability
                for fraud.
              </p>
            </div>
            <div>
              <h3>16 Our contract with you if you are a customer</h3>
              <p>
                If you are contracting as a customer not as a business, we
                intend to rely upon these terms and conditions and any document
                expressly referred to in them in relation to the subject matter
                of any Contract. While we accept responsibility for statements
                and representations made by our duly authorized agents, please
                make sure you ask for any variations from these terms and
                conditions to be confirmed in writing. If you are contracting in
                the course of business, this paragraph 16 does not apply. If you
                are contracting in the course of business please see paragraph
                12 and 15.
              </p>
            </div>
            <div>
              <h3>17 Written communications</h3>
              <p>
                Applicable laws require that some of the information or
                communications we send to you should be in writing. When using
                our site, you accept that communication by us to you will be
                electronic. We will contact you by email or provide you with
                information by posting notices on our site. For contractual
                purposes you agree to this electronic means of communication and
                you acknowledge that all contracts, notices, information and
                other communications that we provide to you electronically
                comply with any legal requirement that such communications be in
                writing. This condition does not affect your statutory rights.
              </p>
            </div>
            <div>
              <h3>18 Notices</h3>
              <p className="p-3">
                18.1 Any notice to be sent by you or by us in connection with
                these terms and condition can be sent by letter or by email.
                Notices to us should be sent to one of the following addresses:{" "}
                <br />
                <br />
                18.1.1 Post: Accounts Receivable, NB Flowers, Fujairah main
                branch, Al Fahem street, Shop # 1 <br />
                <br />
                18.1.2 Email: care@nb-flowers.com <br />
                <br />
                18.2 We will send notices to you by email to the email address
                that you supplied at the time of signing up to our site. <br />
                <br />
                18.3 Either of us can change the address for notices by telling
                the other in writing the new address, but the previous address
                will continue to remain valid for 7 days after the change is
                notified. <br />
                <br />
                18.4 If sent to the correct address, a notice will be treated as
                received 3 working days after sending if a letter or 24 hours if
                sent by email.
              </p>
            </div>
            <div>
              <h3>19 Third party rights</h3>
              <p>
                A person who is not party to these terms and conditions or a
                Contract shall not have any rights under or in connection with
                them.
              </p>
            </div>
            <div>
              <h3>20 Waiver</h3>
              <p>
                The failure of either party to exercise or enforce any right
                conferred on that party by these terms and conditions shall not
                be deemed to be a waiver of any such right or operate to bar the
                exercise or enforcement thereof at any time or times thereafter.
                No waiver by us of any of these terms and conditions will be
                effective unless it is expressly stated to be a waiver and is
                communicated to you in writing.
              </p>
            </div>
            <div>
              <h3>21 Severability</h3>
              <p>
                If any court or competent authority decides that any of the
                provisions of these terms and conditions or any provisions of a
                Contract are invalid, unlawful or unenforceable to any extent,
                the term will, to that extent only, be severed from the
                remaining terms, which will continue to be valid to the fullest
                extent permitted by law.
              </p>
            </div>
            <div>
              <h3>22 Force majeure</h3>
              <p>
                We reserve the right to defer the date of delivery or to cancel
                a Contract for all circumstances beyond its reasonable control,
                including but not limited to any strike, lockout, disorder,
                fire, explosion, accident or stoppage of or affecting our
                business or work and which prevents or hinders the delivery of
                the Goods or the performance of the Services.
              </p>
            </div>
            <div>
              <h3>23 Law and jurisdiction</h3>
              <p>
                These terms and conditions and any dispute or claim arising out
                of or in connection with them or their subject matter or
                formation (including non-contractual disputes or claims) will be
                governed by law. Any dispute or claim arising out of or in
                connection with these terms and conditions or their formation
                (including non-contractual disputes or claims) will be subject
                to the non-exclusive jurisdiction of the courts of UAE.
              </p>
            </div>
          </div>
        </div> */}
        <div className="text-center"></div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

import React, { Children } from "react";
import {
  Categories,
  SelectedOulet,
  StoreData,
  imagePlaceHolder,
} from "../../utils";
import BlockBussinessImage from "./BlockBussinessImage";
import { useTranslation } from "react-i18next";
import { homeStyles } from "../../styles";
import BlockBussinessName from "./BlockBussinessName";
interface Props {
  useData: StoreData | undefined;
  categories: Categories[];
  preorderCategories: Categories[];
  hideMenuCategories: (path: string | undefined, preOrder?: boolean) => void;
  selectedLanguageCode: any;
  orderType: string | undefined;
  selectedOutlet: SelectedOulet;
  bussinessName: string;
  onPressLanguage: (item: any) => void;
}
const BlockAllCategories: React.FC<Props> = (props: Props) => {
  const {
    useData,
    categories,
    preorderCategories,
    selectedLanguageCode,
    selectedOutlet,
    bussinessName,
    hideMenuCategories,
    orderType,
    onPressLanguage,
  } = props;
  const { t } = useTranslation();
  const backgroungImage = require("../../../../assets/img/gradientImage.png");

  const RenderCategoriesBlock = ({ index, item, isPreOrder }: any) => (
    <div
      onClick={() => {
        if (isPreOrder) {
          hideMenuCategories(`list-item${index}`, true);
        } else {
          hideMenuCategories(`list-item${index}`);
        }
      }}
      style={{
        width: "42%",
        marginRight: 26,
        marginBottom: 39,
      }}
    >
      <div key={index}>
        <div>
          {
            <div style={{ marginBottom: 6 }}>
              <div
                style={{
                  backgroundImage: `url(${
                    null !== item?.category_image
                      ? item?.category_image
                      : imagePlaceHolder
                  })`,
                }}
                className={
                  "box_shadow round_cor_grid_view backkground-image-style product_img_heigth_width"
                }
              />
            </div>
          }
          <div className="">
            <div>
              <div
                className=" limit-two-lines categoryName"
                dangerouslySetInnerHTML={{
                  __html: item.name[selectedLanguageCode],
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div
      id="ALL_CATEGORIES"
      className="pop_up_maps_main pop_main_menu_bg"
      lang={selectedLanguageCode}
    >
      <div className="popup_delivery popup_from_bottom pop_up_all_categories popup_slide ">
        <BlockBussinessImage
          useData={useData}
          hideBussinessName={true}
          selectedLanguageCode={selectedLanguageCode}
          selectedOutlet={selectedOutlet}
          onPressLanguage={onPressLanguage}
          bussinessName={bussinessName!}
        />

        <div className="all_categories_linear_backgroung">
          <BlockBussinessName
            selectedLanguageCode={selectedLanguageCode}
            useData={useData}
            selectedOutlet={selectedOutlet}
          />
          <div className="food_categories_title ">{t("delivery_pickup")}</div>
          <div style={homeStyles.flexWrap}>
            {categories &&
              categories.map((item, index) => {
                return <RenderCategoriesBlock item={item} index={index} />;
              })}
          </div>
          {preorderCategories && preorderCategories.length > 0 && (
            <div
              className="food_categories_title "
              style={{ marginBottom: 10, marginTop: 0 }}
            >
              {t("preorder")}
            </div>
          )}
          <div style={homeStyles.flexWrap}>
            {preorderCategories &&
              preorderCategories.map((item, index) => {
                return (
                  <RenderCategoriesBlock
                    item={item}
                    index={index}
                    isPreOrder={true}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockAllCategories;

/* eslint-disable no-throw-literal */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { appSharedColors } from "../../shared/styles/reactStyles";
import ProductDetail from "./Components/Popups/ProductDetail";
import PopDelivery from "./Components/Popups/PopDelivery";
import {
  checkCacheExpiry,
  CLASS_NAME_DELIVERY_TAB,
  CLASS_NAME_NO_PRE_ORDER,
  CLASS_NAME_PICKUP_TAB,
  CLASS_NAME_PREORDER_TAB,
  closeModal,
  DiscountAvailable,
  getDiscountAlertMessage,
  getSelectedLanguage,
  getUserBirthdayDiscount,
  numberWithCommas,
  OutLets,
  saveDataToLocalStorage,
  saveOrderTypeUrlHandler,
  saveUserBirthdayDiscount,
  setCustomerLoyaltyPoints,
  setShopDataToStorage,
  setThemeColor,
  TXT_DELIVERY,
  TXT_PICKUP,
  TXT_PREORDER,
  validateDeliveryPickupPauseOrder,
} from "./utils";
import {
  DATE_FORMAT_STRING,
  isValidOrderType,
} from "./Components/Popups/CartPages/utils";
import SelectAddressModal from "../../shared/components/SelectAddressModal";
import { useScrollLock } from "../../hooks/useScrollLock";
import {
  DeliveryZoneObj,
  SelectedOulet,
  navHighlighter,
  getCustomerInfoFromStroge,
  getWindowSize,
  OutLetsObj,
  getShopDataFromStrg,
  hideMinOrder,
  hideNotificationBar,
  functionChangeLanguge,
} from "./utils";
import { selectedOutInfo } from "../../helper/MatchLocation";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import SelectTimeModal from "./Components/Popups/SetTimePages/SelectTimeModal";
import useBussinessPage from "../../hooks/useBussiness";
import SelectedDateTime from "../../shared/components/SelectedDateTime";
import { AddressInterface } from "./Components/Popups/CartPages/utils";
import SideLogoName from "../../shared/components/SideLogoName";
import Footer from "../../shared/components/Footer";
import PageNotFound from "../../shared/components/PageNotFound";
import Loader from "../../modules/Loader";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import moment from "moment";
import DisplayDiscountMessage from "../../shared/components/DisplayDiscountMessage";
import BlockPickup from "./Components/HomeBlocks/BlockPickup";
import BlockSideMenu from "./Components/HomeBlocks/BlockSideMenu";
import BlockDelivery from "./Components/HomeBlocks/BlockDelivery";
import DisplayMessage from "../../shared/components/DisplayMessage";
import PreOrderModal from "../../shared/components/PreOrderModal";
import BlockBussinessImage from "./Components/HomeBlocks/BlockBussinessImage";
import BlockAllCategories from "./Components/HomeBlocks/BlockAllCategories";
import ModalAddToScreen from "../../shared/components/ModalAddToScreen";
import Loyalty from "../../shared/components/Loyalty";
import LoyaltyModal from "../../shared/components/LoyaltyModal";
const logo = require("../../assets/img/main_logo.png");
interface HomePageProps {
  orderType?: string;
}
const OrderPage: React.FC<HomePageProps> = (props: HomePageProps) => {
  const { bussinessName, itemId } = useParams();
  const navigation = useNavigate();
  const { orderType } = props;
  var windoeSearchParam = window.location.search;
  const { lockScroll, unlockScroll } = useScrollLock();
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const { t } = useTranslation();
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<any>("en");
  const [showDelivery, setShowDelivery] = useState(false);
  const { getOrderForCustometDiscountId, getBirthdayDiscount } =
    useCustomerInfo();
  const finalPathName = "/" + bussinessName;
  const {
    getBussinessData,
    useData,
    notFound,
    categories,
    preOrderCatg,
    getUserToken,
    loading,
  } = useBussinessPage();
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [searchParams] = useSearchParams();
  // birthday discounts varaibles
  // https://order.dukany.io/d/test-resto?isdiscount=yes&mobile=923459635387&coupen=YH943A&dicount=39
  const isBirthDiscount = searchParams.get("isdiscount");
  const birthDaycoupen = searchParams.get("coupen");
  const birthMobile = searchParams.get("mobile");
  const birthDiscountPerc = searchParams.get("dicount");
  //birthday discounts variables
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [visibleDateTimeModal, setVisibleDateTimeModal] =
    useState<boolean>(false);
  var tempSelected = {} as SelectedOulet;
  const [basketQunatity, setBasketQuantity] = useState({ total: 0, price: 0 });
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [showPickup, setShowPickup] = useState<boolean>(false);
  const [showPreOrder, setShowPreOrder] = useState<boolean>(false);
  const [allOutlets, setAllOutlets] = useState<OutLets>();
  const [showLoyaltyModal, setShowLoyaltyModal] = useState<boolean>(false);
  const [showCategories, setShowCategories] = useState<boolean>(true);
  const [isGridView, setIsGridView] = useState<boolean>(false);
  const [showPreOrderModal, setPreOrderModal] = useState<boolean>(false);
  const [showAllCategories, setShowAllCategories] = useState<boolean>(false);
  const [selectedOutlet, setSelectedOutlet] = useState<SelectedOulet>(Object);
  const [discountAvailable, setIsDiscountAvailable] =
    useState<DiscountAvailable>({
      available: false,
      discountType: "",
    });
  const [userSelectedAddress, setUserSelectedAddress] = useState<any>({});
  const [basketItems, setBasketItems] = useState<any>({});
  var customerInfo = getCustomerInfoFromStroge();
  const [askLocation, setAskLocation] = useState<boolean>(false);
  const [showSelectedAddress, setShowSelectedAddress] =
    useState<boolean>(false);
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [showSelectDate, setShowSelectDate] = useState<boolean>(false);
  const parsedData = getShopDataFromStrg(finalPathName);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isPauseOrder, setIsPauseOrder] = useState<boolean>(false);
  const [moveTabClassName, setMoveTabClassName] = useState<string>(
    CLASS_NAME_DELIVERY_TAB
  );
  const [discountObject, setDiscountObj] = useState({});
  const showUserSavedAddress = () => {
    setShowSelectedAddress(true);
    lockScroll();
  };
  const autoSelectTime = () => {
    setDeliveryDate(t("delivery_time"));
  };
  const checkPauseOrderHandler = () => {
    var parsedData = getShopDataFromStrg(finalPathName);
    console.log(
      validateDeliveryPickupPauseOrder(
        parsedData.orderType,
        parsedData.outletInfo
      )
    );
    if (
      validateDeliveryPickupPauseOrder(
        parsedData.orderType,
        parsedData.outletInfo
      )
      //  &&
      // parsedData.orderType !== "pickup"
    ) {
      setIsPauseOrder(true);
      // setShowDetailModal(false);
      setShowSelectDate(false);
      setShowSelectedAddress(false);

      return;
    }
    if (
      !validateDeliveryPickupPauseOrder(
        parsedData.orderType,
        parsedData.outletInfo
      )
    ) {
      setIsPauseOrder(false);
      setShowSelectDate(false);
      setShowSelectedAddress(false);

      return;
    }
    // if (parsedData.orderType === "pickup") {
    //   setIsPauseOrder(false);
    //   return;
    // }
  };
  const closeProductDetailModal = () => {
    if (
      parsedData.outletUrl !== undefined &&
      parsedData?.outletUrl?.includes("item")
    ) {
      window.history.replaceState(
        null,
        "",
        `${
          parsedData.outletUrl !== undefined
            ? parsedData.outletUrl
            : `${orderType}${finalPathName}`
        }`
      );
    } else {
      // navigation(`${orderType}${finalPathName}`);
      window.history.replaceState(null, "", `/d${finalPathName}`);
    }
    closeModal();
    setTimeout(() => {
      setShowDetailModal(false);
      unlockScroll();
    }, 400);
  };

  const hideMenuCategories = (path: string | undefined, preOrder?: boolean) => {
    if (path) {
      var my_element = document.getElementById(path);
      closeModal();
      setTimeout(() => {
        setShowAllCategories(false);
        setShowMenu(false);
        unlockScroll();
      }, 400);
      my_element?.scrollIntoView({
        block: "start",
        inline: "start",
      });
    } else {
      closeModal();
      setTimeout(() => {
        setShowMenu(false);
        unlockScroll();
      }, 400);
    }
    if (preOrder) {
      preOrderTabShowExtras();
      return;
    }
    if (orderType === "d") {
      deliveryTabShowExtras();
      return;
    }
    if (orderType === "p") {
      pickupTabShowExtras();
      return;
    }
  };
  const setPickupLocation = (itemPickup: OutLetsObj, outletName: string) => {
    // var area = outletName.slice(outletName.lastIndexOf("-")).replace("-", "");
    var area = Object.keys(itemPickup.area)[0];
    var minimunBasket =
      // restoData?.outlets[Object.keys(restoData?.outlets)[0]].outlet_id;
      itemPickup?.area[Object.keys(itemPickup?.area)[0]].min_basket_price;
    // @ts-ignore
    tempSelected = {
      //@ts-ignore
      selectedLocationName: itemPickup.outlet_address,
      selectedOutLetName: outletName,
      selectedLatLon: {},
      deliveryZone: {} as DeliveryZoneObj,
      min_basket_price: minimunBasket,
      outlet_address: itemPickup.outlet_address,
      outlet_pickup_payment_methods: itemPickup.outlet_pickup_payment_methods,
      outlet_delivery_payment_methods:
        itemPickup.outlet_delivery_payment_methods,
      outlet_address_arabic: itemPickup.outlet_address_arabic,
      outlet_id: itemPickup.outlet_id,
      outlet_delivery_slot_length: itemPickup.outlet_delivery_slot_length,
      business_hours: itemPickup.business_hours,
      delivery_time: itemPickup.pickup_time,
      vat_tax: itemPickup.vat_tax,
      closed_days: itemPickup.closed_days,
      pauseorder_flag: itemPickup.pauseorder_flag,
      pickup_time: itemPickup.pickup_time,
      time_zone: itemPickup.time_zone,
      estimated_time: itemPickup.estimated_time,
      estimated_time_v1: itemPickup.estimated_time_v1,
      //@ts-ignore
      delivery_fee: 0,
    };
    if (parsedData !== null) {
      // alert('test');
      parsedData.orderType = "pickup";
      // parsedData.outletUrl = `${window.location.pathname}?outlet=${outletName}&area=${area}`;
      parsedData.outletUrl = `${window.location.pathname}?outlet=${outletName}&area=${area}`;
      var shopData = {
        ...parsedData,
        outletInfo: tempSelected,
      };
    }
    localStorage.setItem("shop" + finalPathName, JSON.stringify(shopData));
    setPickupClassName();
    // dispatch(
    // //console.log(area);
    setSelectedOutlet(tempSelected);
    checkPauseOrderHandler();
    window.history.pushState(
      null,
      "New Page Title",
      `${window.location.pathname}?outlet=${outletName}&area=${area}`
    );
    // );
    // window.location.()

    setShowCategories(true);
    if (
      useData?.resto_meta?.BUSSINESS_TIMESLOTS_FLAG === "Yes" &&
      useData?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE !== "Yes"
    ) {
      setShowSelectDate(true);
      lockScroll();
    }
    // if (useData?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE === "Yes") {
    //   autoSelectTime();
    // }
  };
  const getDeliveryDateTime = () => {
    var parsedData = getShopDataFromStrg(finalPathName);
    if (parsedData !== null) {
      if (
        parsedData.selectedDate !== undefined ||
        parsedData.selectedTime !== undefined
      ) {
        if (parsedData.selectedTime === "ASAP") {
          setDeliveryDate(t("delivery_time"));
        } else {
          setDeliveryDate(
            moment(parsedData.dateNumber).format(DATE_FORMAT_STRING) +
              " | " +
              parsedData.selectedTime
          );
        }
      } else {
        setDeliveryDate(t("delivery_time"));
      }
    }
  };
  const setPickupClassName = () => {
    if (
      !useData?.resto_meta?.ALLOW_PRE_ORDERS ||
      "No" === useData?.resto_meta?.ALLOW_PRE_ORDERS
    ) {
      setMoveTabClassName(CLASS_NAME_NO_PRE_ORDER);
      return;
    }

    // if ("No" === useData?.resto_meta?.ALLOW_PRE_ORDERS) {
    //   setMoveTabClassName(CLASS_NAME_NO_PRE_ORDER);
    //   return;
    // }

    if ("Yes" === useData?.resto_meta?.ALLOW_PRE_ORDERS) {
      setMoveTabClassName(CLASS_NAME_PICKUP_TAB);
      return;
    }
  };

  const tokenCheckHandler = async () => {
    // var isExpiried = !checkCacheExpiry(parsedData?.expiredAt, bussinessName!);
    //&& parsedData?.expiredAt
    if (
      parsedData !== null &&
      !checkCacheExpiry(parsedData?.expiredAt, bussinessName!)
    ) {
      getDeliveryDateTime();
      //@ts-ignore
      getBussinessData(
        //@ts-ignore
        parsedData.accessToken,
        finalPathName
      );
      setSelectedId(useData?.id);
    } else {
      getUserToken(finalPathName);
    }
  };
  const showLoyaltyModalHandler = () => {
    lockScroll();
    setShowLoyaltyModal(true);
  };
  const hideLoyaltyModalHandler = () => {
    setShowLoyaltyModal(false);
    unlockScroll();
  };
  const showDeliveryHandler = () => {
    if (parsedData !== null) {
      if (
        parsedData.outletInfo !== undefined &&
        parsedData.outletInfo.selectedLocationName
      ) {
        if (Object.keys(selectedOutlet).length !== 0) {
          showSelectedDateTimeHandler();
        } else {
          if (searchParams.get("outlet") === null) {
            if (customerInfo === null) {
              setShowDelivery(true);
            } else {
              showSelectedDateTimeHandler();
              // showUserSavedAddress();
            }
            lockScroll();
          } else {
            showUserSavedAddress();
            // showSelectedDateTimeHandler();
          }
          // showSelectedDateTimeHandler();
        }
      } else {
        setShowDelivery(true);
        lockScroll();
      }
    }
  };
  const basketHandler = () => {
    var parsedData = getShopDataFromStrg(bussinessName!);
    if (parsedData !== null) {
      parsedData.outletUrl = window.location.pathname + window.location.search;
      var shopData = {
        ...parsedData,
      };
      localStorage.setItem("shop" + finalPathName, JSON.stringify(shopData));
    }
    navigation(`${finalPathName}/basket`);
  };
  const showSelectedDateTimeHandler = () => {
    setVisibleDateTimeModal(true);
    lockScroll();
  };
  const showMenuHandler = () => {
    if (
      useData?.resto_meta.SHOW_CATEGORY_MENU &&
      useData?.resto_meta.SHOW_CATEGORY_MENU === "Yes"
    ) {
      showAllCategoriesHandler();
    } else {
      lockScroll();
      setShowMenu(true);
    }
  };
  const deliveryDateTimeHandler = (selectedDateTime: string) => {
    //console.log(selectedDateTime);
    setDeliveryDate(selectedDateTime);
    setShowSelectDate(false);
    if (!showDetailModal) {
      unlockScroll();
    }
  };
  const deliveryTabShowExtras = () => {
    var shopInfoLocal = getShopDataFromStrg(finalPathName);
    saveOrderTypeUrlHandler(
      shopInfoLocal,
      finalPathName,
      windoeSearchParam,
      TXT_DELIVERY
    );
    setShowPickup(false);
    setShowPreOrder(false);
    setShowCategories(true);
    setMoveTabClassName(CLASS_NAME_DELIVERY_TAB);
  };
  const preOrderTabShowExtras = () => {
    var shopInfoLocal = getShopDataFromStrg(finalPathName);
    saveOrderTypeUrlHandler(
      shopInfoLocal,
      finalPathName,
      windoeSearchParam,
      TXT_PREORDER
    );
    setShowPreOrder(true);
    setShowPickup(false);
    setShowCategories(false);
    setMoveTabClassName(CLASS_NAME_PREORDER_TAB);
  };
  const pickupTabShowExtras = () => {
    setPickupClassName();
    setShowPickup(true);
    setShowCategories(false);
    setShowPreOrder(false);
  };
  const switchTab = (orderType: string) => {
    const parsedData = getShopDataFromStrg(finalPathName);
    // function to check if users moves tab from pickup to delivery
    if (
      parsedData.orderType === TXT_PICKUP ||
      (parsedData.orderType === TXT_PREORDER && orderType === TXT_DELIVERY)
    ) {
      delete parsedData.outletInfo;

      var shopData = {
        ...parsedData,
      };
      // TODO:: we have to change logic need to store seperate pickup and delivery address
      setShopDataToStorage(bussinessName!, shopData); // removing all data from storage and setting valies
      setSelectedOutlet({} as SelectedOulet);
      setUserSelectedAddress({});
    }

    var searchParam = window.location.search;

    switch (orderType) {
      case "delivery":
        deliveryTabShowExtras();

        navigation(`/d${finalPathName}${searchParam}`, {
          replace: true,
        });
        break;
      case "pickup":
        pickupTabShowExtras();
        navigation(`/p${finalPathName}${searchParam}`, {
          replace: true,
        });
        break;
      case "preOrder":
        preOrderTabShowExtras();
        break;

      default:
        break;
    }
  };
  const showDetailExtras = (recepieId: string) => {
    window.history.pushState(
      null,
      "New Page Title",
      `${window.location.pathname}/item/${recepieId}`
    );
    lockScroll();
    setShowDetailModal(true);
  };
  const openPreOrderHandler = (recipe: any) => {
    setSelectedRecipe(recipe.shared_unique_id);
    lockScroll();
    setPreOrderModal(true);
  };
  const hidePreOrderHandler = () => {
    unlockScroll();
    setPreOrderModal(false);
    showDetailExtras(selectedRecipe);
  };
  const onChangeOutlet = (selectedOutlet: any, showTimeModal: boolean) => {
    const parsedData = getShopDataFromStrg(finalPathName);
    var outname = "";
    var areaName = "";

    if (
      useData?.resto_meta?.BUSSINESS_TIMESLOTS_FLAG === "Yes" &&
      showTimeModal
    ) {
      if (parsedData !== null) {
        outname = parsedData?.outletInfo.selectedOutLetName.split("_ao_")[0];
        areaName = parsedData?.outletInfo.selectedOutLetName.split("_ao_")[1];
        setSelectedOutlet(parsedData.outletInfo);
        window.history.pushState(
          null,
          "New Page Title",
          `${window.location.pathname}?outlet=${outname}&area=${areaName}`
        );
      }
      if (useData?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE !== "Yes") {
        setShowSelectDate(true);
        lockScroll();
      }
    } else {
      if (parsedData !== null) {
        outname = parsedData?.outletInfo.selectedOutLetName.split("_ao_")[0];
        areaName = parsedData?.outletInfo.selectedOutLetName.split("_ao_")[1];

        setSelectedOutlet(parsedData.outletInfo);

        window.history.pushState(
          null,
          "New Page Title",
          `${window.location.pathname}?outlet=${outname}&area=${areaName}`
        );
      }
    }

    // if (useData?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE === "Yes") {
    //   autoSelectTime();
    // }
  };
  const closeVisibleDateTimeHandler = () => {
    setVisibleDateTimeModal(false);
    unlockScroll();
  };
  const showDetailLayout = (recipe: any) => {
    setSelectedRecipe(recipe.shared_unique_id);
    showDetailExtras(recipe.shared_unique_id);
  };

  const hideAddressModal = (
    receiver: AddressInterface | undefined,
    buttonPress: string | undefined,
    unLockScroll: boolean,
    showTime?: boolean
  ) => {
    var parsedData = getShopDataFromStrg("/" + bussinessName!);
    var outname = "";
    var areaName = "";

    if (receiver !== undefined) {
      if (buttonPress === "select") {
        if (parsedData !== null) {
          outname = parsedData?.outletInfo.selectedOutLetName.split("_ao_")[0];
          areaName = parsedData?.outletInfo.selectedOutLetName.split("_ao_")[1];
          setSelectedOutlet(parsedData.outletInfo);
          setUserSelectedAddress(parsedData.userAddressInfo);
          window.history.pushState(
            null,
            "New Page Title",
            `${window.location.pathname}?outlet=${outname}&area=${areaName}`
          );
          checkPauseOrderHandler();
        }
        parsedData.outletUrl = `/${orderType}/${bussinessName}?outlet=${outname}&area=${areaName}`;
        localStorage.setItem(
          "shop/" + bussinessName,
          JSON.stringify(parsedData)
        );
        setShowSelectedAddress(false);
        if (unLockScroll) {
          unlockScroll();
        }
        if (showTime) {
          if (
            useData?.resto_meta?.BUSSINESS_TIMESLOTS_FLAG === "Yes" &&
            useData?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE !== "Yes"
          ) {
            setShowSelectDate(true);
            lockScroll();
          }
        }
        if (useData?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE === "Yes") {
          autoSelectTime();
        }
      } else {
        // setUserAddress({} as AddressInterface)
      }
    } else {
      if (buttonPress === "close") {
        setShowSelectedAddress(false);
        unlockScroll();
      }
    }
  };
  const showAllCategoriesHandler = () => {
    if (
      useData &&
      useData.resto_meta.SHOW_CATEGORY_MENU &&
      useData.resto_meta.SHOW_CATEGORY_MENU === "Yes" &&
      parsedData.orderType !== TXT_PREORDER
    ) {
      lockScroll();
      setShowAllCategories(true);
    }
  };
  const checkFromBasketHandler = (catrgoreyItem: any) => {
    var isFound: boolean = false;
    var totalItems: number = 0;
    if (basketItems.cart && basketItems.cart.length > 0) {
      basketItems.cart?.forEach((item: any) => {
        if (useData?.resto_meta.DISABLED_LOCATION_SERVICES != "Yes") {
          if (
            item.id === catrgoreyItem.shared_unique_id &&
            Object.keys(selectedOutlet).length !== 0
          ) {
            totalItems = item.quantity;
            isFound = true;
          }
        } else {
          if (item.id === catrgoreyItem.shared_unique_id) {
            totalItems = item.quantity;
            isFound = true;
          }
        }
      });
    }
    // console.log("isFound: " + isFound);
    return { isFound, totalItems };
  };
  useEffect(() => {
    // console.log(window.location.href);
    ReactGA.send({
      hitType: "pageview",
      page: "/my-test-path",
      title: window.location.pathname + ": GA-4 Test",
    });
    ReactGA.event({
      category: "hello test",
      action: "Hello test",
      label: "Hello test", // optional
      value: 1000, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    tokenCheckHandler();
    if (null !== isBirthDiscount) {
      saveUserBirthdayDiscount(
        isBirthDiscount,
        birthMobile,
        birthDaycoupen,
        birthDiscountPerc
      );
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", navHighlighter); // Add an event listener listening for scroll
    const links = document.querySelectorAll(".navigation ul a");

    function clickHandler(this: any, e: { preventDefault: () => void }) {
      // console.log(document.querySelectorAll('[id^="list0accordion*"]'));
      e.preventDefault();
      const href = this.getAttribute("href");
      // const offsetTop = document.querySelector(href).offsetTop - 80;
      const menu_height =
        document.getElementById("Accordion_tabs")?.clientHeight; // get the height of menu scroll cause it changes in mobile/tab views
      const offsetTop =
        document.querySelector(href).offsetTop - menu_height! + 1;
      // eslint-disable-next-line no-restricted-globals
      scroll({
        top: offsetTop,
        behavior: "smooth",
      });
    }
    for (const link of links) {
      link.addEventListener("click", clickHandler);
    }
  });
  useEffect(() => {
    // //console.log(window.location);
    //alert('test');
    var obj: any = {};
    var localItems = localStorage.getItem("shop" + finalPathName);
    // console.log("localItems");
    //console.log(localItems);
    if (localItems !== null) {
      setBasketItems(JSON.parse(localItems));
      obj = JSON.parse(localItems);
      // //console.log(obj.cart);

      let tempQuntity = 0;
      let tempPrice = 0;
      if (obj.outletInfo) {
        if (searchParams.get("outlet") !== null) {
          setSelectedOutlet(obj.outletInfo);
        }
      }
      console.log("CART");
      // console.log(obj.cart);
      // @ts-ignore
      obj.cart?.forEach((item, index) => {
        tempQuntity = parseFloat(
          (tempQuntity + parseInt(item.quantity)).toFixed(2)
        );
        tempPrice = parseFloat((tempPrice + item?.price).toFixed(2));
      });
      // console.log(tempPrice, tempQuntity);
      setBasketQuantity((prev) => ({
        total: tempQuntity,
        price: tempPrice,
      }));
    }
  }, [showDetailModal]);
  const birthdayDiscountHandler = async (birthdayObj: any) => {
    var birthdayDiscount = {};
    if (useData) {
      birthdayDiscount = await getBirthdayDiscount(customerInfo, birthdayObj);
      var tempDisCheck = isValidOrderType(
        useData?.time_zone,
        //@ts-ignore
        birthdayDiscount,
        orderType!,
        isBirthDiscount
      );
      if (tempDisCheck.available) {
        setDiscountObj(birthdayDiscount);
      }
      setIsDiscountAvailable(tempDisCheck);
    }
  };
  const onSearchPickup = (text: string) => {
    setAllOutlets(
      Object.fromEntries(
        //@ts-ignore
        Object.entries(useData?.outlets).filter(
          (item) =>
            item[1]?.outlet_name
              .toLowerCase()
              .indexOf(text.trim().toLowerCase()) > -1
        )
      )
    );
  };
  useEffect(() => {
    // useData?.resto_meta?.
    if (useData !== undefined) {
      const favicon = document.getElementById("favicon");

      //@ts-ignore
      favicon.href = useData?.site_logo;
      // @ts-ignore
      if (itemId !== undefined && useData !== undefined) {
        setSelectedRecipe(itemId);
        lockScroll();
        setShowDetailModal(true);
        // showDetailExtras(itemId);
      }

      // for checking enabled location
      if (parsedData !== null) {
        if (parsedData.orderType === TXT_PREORDER) {
          preOrderTabShowExtras();
        }
      }
      if (useData?.is_language_option_available === "Yes") {
        var currentLanguage = getSelectedLanguage(bussinessName!);

        if (currentLanguage !== "") {
          setSelectedLanguageCode(currentLanguage);
          functionChangeLanguge(currentLanguage, bussinessName!);
        } else {
          setSelectedLanguageCode(useData?.default_lang);
          functionChangeLanguge(useData?.default_lang, bussinessName!);
        }
      } else {
        setSelectedLanguageCode(useData?.default_lang);
        functionChangeLanguge(useData?.default_lang, bussinessName!);
      }
      // for checking enabled location
      if (orderType === "p" && searchParams.get("outlet") === null) {
        switchTab("pickup");
        setPickupClassName();
      }
      if (orderType === "p" && searchParams.get("outlet") !== null) {
        setShowPickup(true);
        setPickupClassName();
      }
      var newData = getShopDataFromStrg("/" + bussinessName!);
      if (newData !== null) {
        if (newData.userAddressInfo && orderType !== "p") {
          setUserSelectedAddress(newData.userAddressInfo);
        }
        newData.campainType =
          searchParams.get("a") !== null ? searchParams.get("a") : "Direct";
        if (itemId === undefined) {
          newData.outletUrl = window.location.pathname + window.location.search;
        }
        var shopData = {
          ...newData,
        };

        localStorage.setItem("shop" + finalPathName, JSON.stringify(shopData));
        // localStorage.setItem()
      }
      if (
        useData?.resto_meta?.DISABLED_LOCATION_SERVICES === "Yes" &&
        orderType !== "p"
      ) {
        setAskLocation(true);
        var firstoOutLetName = Object.keys(useData?.outlets)[0];
        var areName = Object.keys(useData?.outlets[firstoOutLetName]?.area)[0];
        var firstDeliveryZone = Object.keys(useData?.delivery_zones)[0];
        var firstDeliveryZonesData = useData?.delivery_zones[firstDeliveryZone];
        var locationText =
          useData?.outlets[firstoOutLetName].outlet_address[
            selectedLanguageCode
          ];

        window.history.pushState(
          null,
          "New Page Title",
          `${window.location.pathname}?outlet=${firstoOutLetName}&area=${areName}`
        );

        selectedOutInfo(
          firstoOutLetName + "_ao_" + areName,
          firstDeliveryZonesData,
          locationText,
          {},
          useData,
          finalPathName
        );
        // if (useData?.resto_meta?.ENABLED_AS_SOON_AS_POSSIBLE === "Yes") {
        //   autoSelectTime();
        // }
      }

      if (useData?.resto_meta?.PRODUCT_ORIENTATION !== undefined) {
        if (useData?.resto_meta?.PRODUCT_ORIENTATION === "LIST") {
          setIsGridView(false);
        } else {
          setIsGridView(true);
        }
      }

      var tempDisCheck = isValidOrderType(
        useData?.time_zone,
        useData?.discounts,
        orderType!,
        isBirthDiscount
      );
      setIsDiscountAvailable(tempDisCheck);
      setAllOutlets(useData?.outlets);
      checkPauseOrderHandler();
    }
    onTop();
  }, [useData, shopInfo]);
  const onTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1500);
  };
  useEffect(() => {
    // onTop();
    window.onpopstate = (e) => {
      e.preventDefault();
      // onPressClose();
      //your code...
    };
  }, []);

  useEffect(() => {
    if (!visibleDateTimeModal) {
      getDeliveryDateTime();
    }
  }, [visibleDateTimeModal]);
  useEffect(() => {
    var parsedData = getShopDataFromStrg("/" + bussinessName!);
    if (parsedData !== null && shopInfo !== undefined) {
      var shopData = {
        ...parsedData,
        shopInfo: shopInfo,
      };
      localStorage.setItem("shop" + finalPathName, JSON.stringify(shopData));
    }
    //@ts-ignore
    var discountObj = shopInfo[discountAvailable.discountType];
    //@ts-ignore
    setDiscountObj(discountObj);
    setThemeColor(shopInfo);
    var isBirthDayDiscount = getUserBirthdayDiscount();

    if (parsedData !== null && customerInfo !== null) {
      if (isBirthDayDiscount && isBirthDayDiscount.isdiscount == "yes") {
        birthdayDiscountHandler(isBirthDayDiscount);
        return;
      }

      if (discountObj && !isBirthDayDiscount) {
        getOrderForCustometDiscountId(
          customerInfo?.id,
          //@ts-ignore
          discountObj.id,
          parsedData.accessToken
        ).then((val) => {
          if (val) {
            var shopData = {
              ...parsedData,
              shopInfo: { ...shopInfo, orderDetails: val },
            };
            setDiscountObj(discountObj);
            localStorage.setItem(
              "shop" + finalPathName,
              JSON.stringify(shopData)
            );
          }
        });
      }
    }
  }, [shopInfo]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if (!loading) {
      showAllCategoriesHandler();
    }
  }, [loading]);
  return (
    <>
      <div id="homePage" className="homePage" lang={selectedLanguageCode}>
        {!notFound ? (
          <>
            {!loading ? (
              <div className="landscape" id="">
                <BlockBussinessImage
                  useData={useData}
                  selectedLanguageCode={selectedLanguageCode}
                  selectedOutlet={selectedOutlet}
                  onPressLanguage={(item) => setSelectedLanguageCode(item)}
                  bussinessName={bussinessName!}
                  showPickup={showPickup}
                />
                <div className="">
                  <div id="exTab2" className="container">
                    {!hideMinOrder(selectedOutlet, useData, orderType) ? (
                      <div>
                        {selectedOutlet !== undefined &&
                          Object.keys(selectedOutlet)?.length > 0 && (
                            <div className="" style={{ marginLeft: 8 }}>
                              {!showPickup && (
                                <div className="minimun-basket-wrapper">
                                  <div
                                    // style={{ width: "20%" }}
                                    className={`${
                                      selectedLanguageCode === "en"
                                        ? "border_right"
                                        : "border_left pad_15"
                                    }  pr26 `}
                                  >
                                    <div
                                      className="light_grey_text font-10 half_width aed_t_pad"
                                      style={{
                                        marginTop: 2,
                                        marginLeft: 1,
                                        marginRight: 5,
                                      }}
                                    >
                                      {selectedOutlet.min_basket_price !== 0 &&
                                        //@ts-ignore
                                        useData?.resto_meta.BUSSINESS_CCY_LANG[
                                          selectedLanguageCode
                                        ]}
                                    </div>
                                    <div className="add-dots font-bolder">
                                      <text>
                                        {selectedOutlet.min_basket_price === 0
                                          ? t("no_minimum_homepage")
                                          : selectedOutlet.min_basket_price}
                                      </text>
                                    </div>
                                    <div className="light_grey_text font-10">
                                      {t("minumun_text")}
                                    </div>
                                  </div>
                                  <div className="pl12">
                                    <div className="">
                                      <text className="font-12 font-bolder">
                                        {
                                          selectedOutlet.estimated_time_v1
                                            ?.text_to_display
                                        }
                                      </text>
                                    </div>
                                    <div className="light_grey_text font-10">
                                      {t(
                                        `${selectedOutlet.estimated_time_v1?.type_to_display.toLocaleLowerCase()}`
                                      ).toUpperCase()}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    ) : (
                      <></>
                    )}

                    {discountAvailable.available && !showPreOrder && (
                      <DisplayDiscountMessage
                        showIcon={true}
                        message={
                          //@ts-ignore
                          useData?.discounts?.discount_name[
                            selectedLanguageCode
                          ]
                        }
                      />
                    )}

                    {useData?.loyalties !== null && (
                      <div onClick={showLoyaltyModalHandler}>
                        <Loyalty
                          setUserLoyaltyPoints={(points) =>
                            setCustomerLoyaltyPoints(points, bussinessName!)
                          }
                          makeApiCall={true}
                          title={t("lbl_loyalty_disp_heading")}
                          message={t("lbl_loyalty_disp_desc").replace(
                            "{#cashback}",
                            `${useData?.loyalties.cash_back.with_percentage}`
                          )}
                          // message={`Earn ${useData?.loyalties.cash_back.with_percentage} cashback to spend in your next orders.`}
                          showIcon
                        />
                      </div>
                    )}

                    {showPreOrder && (
                      <DisplayMessage
                        whatsappNumber={useData?.whatsapp_number}
                        landlineNumber={useData?.phone}
                        showIcon={false}
                        message={
                          selectedLanguageCode === "en"
                            ? "For pre order kindly contact us on our phone numbers to place an order"
                            : "يرجى التواصل معنا عبر ارقام الهاتف للتواصي"
                        }
                      />
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 pl-5 pr-5">
                          <div className="tabbable-panel">
                            <div className="tabbable-line ">
                              <ul className="nav nav-tabs disp_flex_row">
                                <div className={moveTabClassName}></div>
                                <li
                                  className={`${
                                    !showPickup &&
                                    !showPreOrder &&
                                    "active delivery"
                                  }`}
                                >
                                  <a
                                    onClick={() => switchTab("delivery")}
                                    id="show_bottom"
                                    data-toggle="tab"
                                  >
                                    {t("delivery").toUpperCase()}
                                  </a>
                                </li>
                                {useData &&
                                  useData.resto_meta?.ALLOW_PRE_ORDERS &&
                                  useData.resto_meta?.ALLOW_PRE_ORDERS ===
                                    "Yes" && (
                                    <li
                                      className={`${
                                        showPreOrder && "active pre-order"
                                      }`}
                                    >
                                      <a
                                        onClick={() => switchTab("preOrder")}
                                        id="hide_bottom"
                                        data-toggle="tab"
                                      >
                                        {t("preorder").toUpperCase()}
                                      </a>
                                    </li>
                                  )}
                                {/* <div className="btn_bg"></div> */}
                                {useData?.is_pick_available === "Yes" && (
                                  <li
                                    className={`${
                                      showPickup && "active pickup"
                                    }`}
                                  >
                                    <a
                                      onClick={() => switchTab("pickup")}
                                      id="hide_bottom"
                                      data-toggle="tab"
                                    >
                                      {t("pickup").toUpperCase()}
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  // Object.keys(selectedOutlet).length !== 0
                  !hideNotificationBar(selectedOutlet, useData, orderType) ? (
                    // hideMinOrder(selectedOutlet, useData, orderType)
                    <div>
                      {/* for delivery information */}

                      <div
                        className="tab-content"
                        style={{
                          display: !showCategories ? "none" : "block",
                        }}
                      >
                        <div
                          className={`tab-pane ${!showPreOrder && "active"} `}
                          id="tab_default_1"
                        >
                          {showCategories && (
                            <div
                              className="pad_bottom "
                              style={{ marginBottom: 5 }}
                            >
                              <div
                                className="col_30_percent add-dots"
                                onClick={showDeliveryHandler}
                              >
                                <b style={{ fontSize: 12 }}>
                                  {" "}
                                  {orderType === "d"
                                    ? t("choose_delivery")
                                    : t("picking_up_from")}
                                </b>
                              </div>
                              <div
                                className="col_10_percent glyphicon glyphicon-map-marker f_size"
                                onClick={showDeliveryHandler}
                              ></div>
                              <div
                                className="col_50_percent add-dots"
                                id="Delivery_to"
                                onClick={showDeliveryHandler}
                              >
                                {userSelectedAddress !== undefined &&
                                  orderType === "p" &&
                                  Object?.keys(userSelectedAddress).length !==
                                    0 && (
                                    <>
                                      <text className="font-12">
                                        {userSelectedAddress
                                          ? userSelectedAddress?.address
                                          : t("select_location")}
                                      </text>
                                    </>
                                  )}

                                {selectedOutlet &&
                                  Object.keys(selectedOutlet).length > 0 && (
                                    <span>
                                      {orderType === "d" && (
                                        <text className="font-12">
                                          {selectedOutlet.selectedLocationName
                                            ? selectedOutlet.selectedLocationName
                                            : t("select_location")}
                                        </text>
                                      )}
                                      {orderType === "p" && (
                                        <text className="font-12">
                                          {selectedOutlet.selectedLocationName
                                            ? selectedOutlet
                                                .selectedLocationName[
                                                selectedLanguageCode
                                              ]
                                            : t("select_location")}
                                        </text>
                                      )}
                                    </span>
                                  )}
                              </div>

                              <div
                                className="col_30_percent add-dots"
                                // onClick={showDateModalHandler}
                                onClick={showDeliveryHandler}
                              >
                                <text className="font-12">
                                  (
                                  {deliveryDate !== ""
                                    ? deliveryDate
                                    : t("select_time")}
                                  )
                                </text>
                              </div>
                              <div className="col_10_percent">
                                <a
                                  // href="#pop_delivery"
                                  className="target_slide"
                                  // onClick={showDateModalHandler}
                                  onClick={showDeliveryHandler}
                                >
                                  <span className="glyphicon glyphicon-menu-down"></span>
                                </a>
                              </div>
                            </div>
                          )}
                          {/* <br /> */}
                          {isPauseOrder && (
                            <div className="not_accepting_order_delivery">
                              {t("alert_pause_order_msg")}
                            </div>
                          )}
                        </div>
                      </div>
                      {!showPreOrder && showCategories && (
                        <div className="notification-bar">
                          <span>
                            {" "}
                            {t("notifcation_note")}{" "}
                            {useData?.name[selectedLanguageCode]}{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )
                }

                {(showCategories || showPreOrder) && (
                  <div
                    className="sticky_tabs tab_default_2_hide"
                    id="Accordion_tabs"
                  >
                    <div className="menu_wrap">
                      <div className="x-scroll" id="mainNav">
                        <a className="target_slide">
                          <span
                            className="burger_custom"
                            id="burger_custom"
                            onClick={showMenuHandler}
                          >
                            <div className="burger_rect"></div>
                            <div className="burger_rect"></div>
                            <div className="burger_rect"></div>
                          </span>
                        </a>
                        <nav className="navigation">
                          <ul>
                            <li className="remove_dot">
                              {showCategories &&
                                categories?.map((item, index) => {
                                  return (
                                    <>
                                      {item.recipes !== undefined && (
                                        <span
                                          key={index}
                                          id={`list${index}accordion${index}`}
                                          className={
                                            item.id == selectedId || index == 0
                                              ? "under_line_b selected"
                                              : "under_line_b"
                                          }
                                          // onClick={(e) => setSelectedCategory(item)}
                                        >
                                          <a
                                            id={`list${index}accordion${index}`}
                                            className={
                                              item.id == selectedId ||
                                              index == 0
                                                ? "link_btn active"
                                                : "link_btn"
                                            }
                                            href={`#list-item${index}`}
                                          >
                                            {item?.name[selectedLanguageCode]}
                                          </a>
                                        </span>
                                      )}
                                    </>
                                  );
                                })}
                              {showPreOrder &&
                                preOrderCatg?.map((item, index) => {
                                  return (
                                    <>
                                      {item.recipes !== undefined && (
                                        <span
                                          key={index}
                                          id={`list${index}accordion${index}`}
                                          className={
                                            item.id == selectedId || index == 0
                                              ? "under_line_b selected"
                                              : "under_line_b"
                                          }
                                          // onClick={(e) => setSelectedCategory(item)}
                                        >
                                          <a
                                            id={`list${index}accordion${index}`}
                                            className={
                                              item.id == selectedId ||
                                              index == 0
                                                ? "link_btn active"
                                                : "link_btn"
                                            }
                                            href={`#list-item${index}`}
                                          >
                                            {item?.name[selectedLanguageCode]}
                                          </a>
                                        </span>
                                      )}
                                    </>
                                  );
                                })}
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                )}

                {/* Block Pickup */}
                {!showCategories && !showPreOrder && (
                  <BlockPickup
                    setPickupLocation={setPickupLocation}
                    allOutlets={allOutlets}
                    onSearchPickup={onSearchPickup}
                  />
                )}

                {/* Block Pickup */}

                {showAllCategories && (
                  <BlockAllCategories
                    preorderCategories={preOrderCatg}
                    categories={categories}
                    orderType={orderType}
                    hideMenuCategories={hideMenuCategories}
                    useData={useData}
                    selectedLanguageCode={selectedLanguageCode}
                    selectedOutlet={selectedOutlet}
                    onPressLanguage={(item) => setSelectedLanguageCode(item)}
                    bussinessName={bussinessName!}
                  />
                )}
                {/* delivery  section */}
                {showCategories && !showPreOrder && (
                  <BlockDelivery
                    categories={categories}
                    selectedOutlet={selectedOutlet}
                    isPauseOrder={orderType !== "p" ? isPauseOrder : false}
                    setIsPauseOrder={(value) => setIsPauseOrder(value)}
                    isGridView={isGridView}
                    checkFromBasketHandler={checkFromBasketHandler}
                    selectedLanguageCode={selectedLanguageCode}
                    useData={useData}
                    showDetailLayout={showDetailLayout}
                    discountAvailable={discountAvailable}
                  />
                )}
                {showPreOrder && (
                  <BlockDelivery
                    isPauseOrder={false}
                    setIsPauseOrder={(value) => setIsPauseOrder(value)}
                    categories={preOrderCatg}
                    isGridView={isGridView}
                    checkFromBasketHandler={checkFromBasketHandler}
                    selectedLanguageCode={selectedLanguageCode}
                    useData={useData}
                    // showDetailLayout={showDetailLayout}
                    showDetailLayout={openPreOrderHandler}
                    discountAvailable={discountAvailable}
                  />
                )}
                {/* delivery section  end*/}
                {/* Menu categories popup */}
                {showMenu && (
                  <BlockSideMenu
                    selectedLanguageCode={selectedLanguageCode}
                    hideMenuCategories={hideMenuCategories}
                    categories={showPreOrder ? preOrderCatg : categories}
                  />
                )}
                {/* Menu categories popup */}

                {/* @ts-ignore */}
                {useData?.resto_meta.DISABLED_LOCATION_SERVICES === "Yes" && (
                  <>
                    {basketItems.cart?.length > 0 &&
                      !showDelivery &&
                      showCategories &&
                      !showSelectedAddress &&
                      !visibleDateTimeModal &&
                      !showMenu &&
                      !showSelectDate &&
                      !isPauseOrder &&
                      !showDetailModal && (
                        <div
                          className="view-basket"
                          style={{ display: "block" }}
                          onClick={() => {
                            basketHandler();
                          }}
                        >
                          <div className="view-cart-wrap">
                            <div className="pl-3 display_row_reverse">
                              <span className="t-price" id="basket-price">
                                {numberWithCommas(basketQunatity.price)}{" "}
                                {selectedLanguageCode === "ar"
                                  ? useData?.resto_meta.BUSSINESS_CCY_LANG.ar
                                  : useData?.resto_meta.BUSSINESS_CCY_LANG.en}
                              </span>
                              <span className="cart-item total-recipe-selected">
                                {basketQunatity.total}
                              </span>
                            </div>

                            <div className="vc-name-image">
                              <p className="next-page">
                                <span className="vc-name">
                                  {t("my_basket")}
                                </span>
                                <img
                                  src={require("../../assets/img/shopping-bag.png")}
                                  alt=""
                                  width={14}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
                {useData?.resto_meta.DISABLED_LOCATION_SERVICES === "No" && (
                  <>
                    {basketItems.cart?.length > 0 &&
                      Object.keys(selectedOutlet).length !== 0 &&
                      !showDelivery &&
                      showCategories &&
                      !showSelectedAddress &&
                      !visibleDateTimeModal &&
                      !showMenu &&
                      !isPauseOrder &&
                      !showSelectDate &&
                      !showDetailModal && (
                        <div
                          className="view-basket"
                          style={{ display: "block" }}
                          onClick={() => {
                            basketHandler();
                          }}
                        >
                          <div className="view-cart-wrap">
                            <div className="pl-3 display_row_reverse">
                              <span className="t-price" id="basket-price">
                                {numberWithCommas(basketQunatity.price)}{" "}
                                {selectedLanguageCode === "ar"
                                  ? useData?.resto_meta.BUSSINESS_CCY_LANG.ar
                                  : useData?.resto_meta.BUSSINESS_CCY_LANG.en}
                              </span>
                              <span className="cart-item total-recipe-selected">
                                {basketQunatity.total}
                              </span>
                            </div>

                            <div className="vc-name-image">
                              <p className="next-page">
                                <span className="vc-name">
                                  {t("my_basket")}
                                </span>
                                <img
                                  src={require("../../assets/img/shopping-bag.png")}
                                  alt=""
                                  width={14}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}

                {/* Footer */}
                <Footer bussinessDetails={useData} />

                {/* Product detail popup */}
                {showDetailModal && (
                  <ProductDetail
                    isDiscountAvailable={discountAvailable}
                    onPressClose={() => {
                      closeProductDetailModal();
                    }}
                    hideAddressModal={(receiver, buttonPress) =>
                      hideAddressModal(receiver, buttonPress, false, true)
                    }
                    selectedOutlet={selectedOutlet}
                    onChangeOutlet={onChangeOutlet}
                    outletName={finalPathName}
                    showPreOrder={showPreOrder}
                    userToken={parsedData !== null && parsedData.accessToken}
                    selectedRecipe={selectedRecipe}
                    isLocationSelectedFlag={
                      Object.keys(selectedOutlet).length === 0 ? false : true
                    }
                    currentLanguage={selectedLanguageCode}
                    restoDetails={useData}
                  />
                )}
                <ModalAddToScreen />
                {showDelivery ? (
                  <>
                    <PopDelivery
                      restoData={useData}
                      onSelectedOutlet={(selectedOutlet: any) => {
                        onChangeOutlet(selectedOutlet, true);
                      }}
                      onPressClose={() => {
                        setShowDelivery(false);
                        unlockScroll();
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                {showSelectDate && (
                  <SelectTimeModal
                    shopName={bussinessName!}
                    onPressClose={(selectedDateTime: string) => {
                      deliveryDateTimeHandler(selectedDateTime);
                    }}
                  />
                )}
                {showSelectedAddress && (
                  <SelectAddressModal
                    selectedId={
                      parsedData !== null && parsedData?.userAddressInfo?.id
                    }
                    onPressClose={(
                      receiver: AddressInterface | undefined,
                      buttonPress: string | undefined
                    ) => hideAddressModal(receiver, buttonPress, true)}
                    customerId={customerInfo !== null && customerInfo?.id}
                    accesssToken={parsedData !== null && parsedData.accessToken}
                    selectedLatLng={
                      parsedData !== null &&
                      parsedData.outletInfo.selectedLatLon
                    }
                  />
                )}
                {useData && showLoyaltyModal && (
                  <LoyaltyModal
                    onPressClose={hideLoyaltyModalHandler}
                    loyalties={useData?.loyalties}
                    selectedLang={selectedLanguageCode}
                  />
                )}

                {showPreOrderModal && (
                  <PreOrderModal
                    showButton={true}
                    landLinenumber={useData?.phone}
                    whatsappNumber={useData?.whatsapp_number}
                    onBackDropClick={() => {}}
                    onPressClose={hidePreOrderHandler}
                    headerMessage={t("preorder")}
                    errorMessage={
                      selectedLanguageCode === "en"
                        ? "For pre order kindly contact us on our phone numbers to place an order"
                        : "يرجى التواصل معنا عبر ارقام الهاتف للتواصي"
                    }
                  />
                )}
                {visibleDateTimeModal && (
                  <SelectedDateTime
                    isNew={
                      Object.keys(selectedOutlet).length !== 0 ? false : true
                    }
                    orderType={orderType!}
                    onSelectedAddress={(receiver, buttonPressed) => {
                      hideAddressModal(receiver, buttonPressed, true);
                      closeVisibleDateTimeHandler();
                    }}
                    selectedLanguageCode={selectedLanguageCode}
                    restoData={useData}
                    onPressClose={() => {
                      getDeliveryDateTime();
                      closeVisibleDateTimeHandler();
                    }}
                    onSelectedOutlet={(selectedOutlet: any) => {
                      onChangeOutlet(selectedOutlet, false);
                      // getDeliveryDateTime();
                    }}
                    changePickup={() => {
                      switchTab("pickup");
                      closeVisibleDateTimeHandler();
                    }}
                    deliveryDateTimeChange={(selectedDateTime: any) => {}}
                  />
                )}
              </div>
            ) : (
              <div className={" loader-wrapper"}>
                <Loader
                  width={50}
                  height={50}
                  colorProp={appSharedColors.primaryColor}
                />
              </div>
            )}
          </>
        ) : (
          <div className="landscape">
            <PageNotFound />
          </div>
        )}
        {!notFound && (
          <SideLogoName
            logoUrl={
              useData !== undefined && useData.site_logo !== undefined
                ? useData?.site_logo
                : logo
            }
            bussinessName={useData?.name[selectedLanguageCode]}
          />
        )}
      </div>
    </>
  );
};

export default OrderPage;
